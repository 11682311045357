<template>
    <div class="edu-frame">
        <edu-nav-bar title="确认课程" :show="show" @showPopup="show=true" @hidePopup="show=false"></edu-nav-bar>
        <div class="content-frame">
            <!--<div v-if="notPaysNoBillNos != null && notPaysNoBillNos.length > 0" class="couponTipsBtn" @click="showCouponPop=true;">优惠券</div>-->
            <div class="edu-group" style="margin: 20px auto;">
                <div class="step-tips">您好，{{cust.fullName}}。以下信息为您当前所报名的班级情况：</div>
                <div class="step-tips-detail">
                    <p>若还想继续选择班级，可点击“继续选报班级”进入选择报名班级界面。</p>
                    <p v-if="LOGINTYPE_OLD == cust.loginType">注意：课程报名缴费成功后，有关退、换班的规定详情请参阅《招生简章》。</p>
                    <!--<p v-else>注意：课程报名缴费成功后72小时内可在报名系统自行办理退班（每人仅限一次，老学员报读本班和开学后报班的恕不办理退换），逾期不再办理。</p>-->
                    <p v-else>注意：课程报名缴费成功后，有关退、换班的规定详情请参阅《招生简章》。</p>
                    <div v-if="countDown > 0">
                        请在<span style="color: red;font-size: 18px;font-weight: bold">{{countDownTxt}}</span> 内完成缴费，否则所报课程自动取消。
                    </div>
                    <div v-else>
                        已经超过缴费时间限制，课程已被取消。
                    </div>
                </div>
            </div>

            <div class="class-list">
                <div class="title-tips">确认您所选的课程：</div>
                <!--已提交部分-->
                <div v-if="notPaysExistBillNos != null" style="margin-top: 10px;margin-bottom: 10px;">
                    <div class="tipsTitle">进行中</div>
                    <div v-for="(item, key) in notPaysExistBillNos" :key="key">
                        <div style="margin-left: 10px;margin-right: 10px;margin-top: 10px;display: flex;justify-content: center;align-items: center;">
                            <div style="flex: 1;">单号：{{key}}</div>
                            <div style="display: flex;align-items: flex-end;">
                                <van-button :loading="submitting" square size="small" type="danger" @click="cancelCourseForPay(key)">取消</van-button>
                            </div>
                        </div>
                        <div class="edu-group class-item" v-for="(course, index) in item" :key="index">
                            <div class="class-detail">
                                <div class="class-detail-title">
                                    <div class="class-detail-title-name">{{course.className}}</div>
                                    <van-tag type="warning" v-if="course.isPay == 1">
                                        {{ course.rcMoney > 0 ? '已缴费' : '已确认' }} <!--如果金额大于0 显示缴费， 否则显示确认-->
                                    </van-tag>
                                    <van-tag type="warning" v-else>未缴费</van-tag>
                                </div>
                                <div class="class-detail-more">
                                    <div class="class-detail-title-name">
                                        <div>上课时间：{{ course.classTime }}</div>
                                        <div class="class-detail-row">
                                            <div class="class-detail-row-title">任课老师：{{ course.teacherId }}</div>
                                            <!-- <div class="class-detail-row-title">人数：{{ course.qty }}</div> -->
                                      </div>
                                      <div class="class-detail-row">
                                          <div class="class-detail-row-title">学费：<label class="txt-price">{{ course.rcMoney > 0 ? course.rcMoney : '免费' }}</label></div>
                                      </div>
                                  </div>
                                  <!--<div style="display: flex;align-items: flex-end;">
                                      <van-button square size="small" type="danger" @click="cancelCourseForPay(course.regClassId)">取消</van-button>
                                  </div>-->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="moneyTotal">
                        <div style="flex: 1;color: red;">学费合计：</div>
                        <div style="flex: 1;justify-content: flex-end;text-align: right;color: red;">{{notPayTotal - notPayNoBillNoTotal}}元</div>
                    </div>
                </div>
                <!--已提交部分-->

                <!--未提交部分-->
                <div v-if="notPaysNoBillNos != null && notPaysNoBillNos.length > 0" style="margin-top: 10px;margin-bottom: 10px;">
                    <div class="tipsTitle">未提交</div>
                    <div v-for="(item,index) in notPaysNoBillNos" :key="index">
                        <div class="edu-group class-item">
                            <div class="class-detail">
                                <div class="class-detail-title">
                                    <div class="class-detail-title-name">{{item.className}}</div>
                                    <van-tag type="warning" v-if="item.isPay==1">未缴费</van-tag>
                                    <van-tag type="warning" v-else>未缴费</van-tag>
                                </div>
                                <div class="class-detail-more">
                                    <div class="class-detail-title-name">
                                        <div>上课时间：{{item.classTime}}</div>
                                        <div class="class-detail-row">
                                            <div class="class-detail-row-title">任课老师：{{item.teacherId}}</div>
                                            <!-- <div class="class-detail-row-title">人数：{{ item.qty }}</div> -->
                                        </div>
                                        <div class="class-detail-row">
                                            <div class="class-detail-row-title">学费：<label class="txt-price">{{ item.rcMoney > 0 ? item.rcMoney : '免费' }}</label></div>
                                        </div>
                                    </div>
                                    <div style="display: flex;align-items: flex-end;">
                                        <van-button :loading="submitting" square size="small" type="danger" @click="cancelCourse(item.regClassId)">取消</van-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="moneyTotal">
                        <div style="flex: 1;color: red;">学费合计：</div>
                        <div style="flex: 1;justify-content: flex-end;text-align: right;color: red;">{{notPayNoBillNoTotal}}元</div>
                    </div>
                </div>
                <!--未提交部分-->

            </div>
        </div>

        <div class="bottom-frame">
            <div class="bottom-frame-sum">
                <div v-if="coupon.totalMoney != null && coupon.totalMoney > 0" style="color: blue;">
                    优惠券折后金额：<span style="color: red;font-size: 20px;">{{ notPayNoBillNoTotal >= coupon.totalMoney ? (notPayNoBillNoTotal * 100 - coupon.totalMoney * 100) / 100 : 0 }}元</span>
                </div>
                <div v-else>未支付合计：<span style="color: red;font-size: 20px;">{{notPayNoBillNoTotal}}元</span></div>
            </div>
            <div class="bottom-frame-fun" >
                <van-button :loading="submitting" square style="width: 120px" type="primary" @click="toPay('wechat')" v-if="!isWeb && notPaysNoBillNos != null && notPaysNoBillNos.length > 0 && notPayNoBillNoTotal > 0 && (notPayNoBillNoTotal > (coupon.totalMoney == null ? 0 : coupon.totalMoney))">微信支付</van-button>
                <van-button :loading="submitting" square style="width: 120px" type="info" @click="toPay('alipay')" v-if="isWeb && notPaysNoBillNos != null && notPaysNoBillNos.length > 0 && notPayNoBillNoTotal > 0 && (notPayNoBillNoTotal > (coupon.totalMoney == null ? 0 : coupon.totalMoney))">支付宝支付</van-button>
                <van-button :loading="submitting" square style="width: 120px" type="primary" @click="toPay('nopay')" v-if="(notPaysNoBillNos != null && notPaysNoBillNos.length > 0 && (notPayNoBillNoTotal == 0 || (notPayNoBillNoTotal <= (coupon.totalMoney == null ? 0 : coupon.totalMoney)))) ">确认报读</van-button>
            </div>
        </div>

        <!--Coupon-->
        <van-popup v-model="showCouponPop" closeable position="bottom" :style="{ height: '30%' }">
            <div style="width: 100%;display: flex;justify-content: center;align-items: center;height: 60px;">请输入优惠券号码</div>
            <van-field v-model="coupon.couponId" label="优惠券号码" placeholder="请输入优惠券号码" style="width: 80%;margin: 0 auto;"/>
            <div style="display: flex;position: absolute;bottom: 0;width: 100%;">
                <van-button block square type="info" @click="cancelCoupon" v-if="notPaysNoBillNos != null && notPaysNoBillNos.length > 0">不使用优惠券</van-button>
                <van-button block square type="primary" @click="useCoupon" v-if="notPaysNoBillNos != null && notPaysNoBillNos.length > 0">使用优惠券</van-button>
            </div>
        </van-popup>
        <!--Coupon-->

        <!--支付宝支付表单-->
        <div id="alipayform"></div>
        <!--支付宝支付表单-->
    </div>
</template>
<script>
    import {Button, Tag, Popup, Field} from "vant";
    import EduNavBar from "@/components/EduNavBar";
    import Tools from "@/api/Tools";
    import EduConstants from "@/api/EduConstants";
    import BaomingApi from "@/api/BaomingApi";
    import wx from "weixin-js-sdk"

    export default {
        name: 'Qrkc',
        components: {
            EduNavBar,
            VanButton: Button,
            VanTag: Tag,
            VanPopup: Popup,
            VanField: Field
        },
        data() {
            return {
                cust: Tools.getCurCust(),
                isWeb: !EduConstants.isMobile(),
                LOGINTYPE_OLD: EduConstants.LOGINTYPE_OLD,
                show: false,
                timer: null,
                countDown: 0, // 60 * 15, // 倒计时
                countDownTxt: '15分钟00秒',
                notPaysExistBillNos: null, // 已经提交但没支付（有单号）
                notPaysNoBillNos: null, // 没提交的数据（无单号）
                notPayNoBillNoTotal: 0,
                notPayTotal: 0,
                submitting: false,
                showCouponPop: false,
                coupon: {}
            }
        },
        methods: {
            cancelCoupon() {
                this.showCouponPop = false
                this.coupon = {}
            },
            useCoupon() {
                this.showCouponPop = false
                BaomingApi.getCoupon({ couponId: this.coupon.couponId, loginType: this.cust.loginType }).then(response=>{
                    if (response.code) {
                        console.log(response.res)
                        this.coupon = response.res
                        this.showCouponPop = false
                    }
                })

            },
            toPay(payType) {
                // console.log(this.countDown)
                if (this.submitting==true) {
                  return;
                }
                if (this.countDown <= 0) {
                    this.$dialog({message: '已经超过缴费时间限制，课程已被取消。'})
                    return
                }
                var couponId = null
                var couponMoney = null
                if (this.coupon.couponId!=null && this.coupon.totalMoney != null && this.coupon.couponId!=undefined && this.coupon.totalMoney != undefined) {
                    couponId = this.coupon.couponId
                    couponMoney = this.coupon.totalMoney
                }
                var self = this
                var condition = { payType: payType, loginType: this.cust.loginType, custId: this.cust.custId, couponId: couponId, couponMoney: couponMoney, weixinOpenId: Tools.getSession('openId') }
                this.submitting = true
                BaomingApi.toPay(condition).then(response => {
                    this.submitting = false
                    // 微信支付
                    if (response.code == 100) {
                        if (payType == 'alipay') {
                            const div = document.getElementById('alipayform')
                            div.innerHTML = response.res.data
                            document.body.appendChild(div)
                            document.forms[0].submit()
                        } else if (payType == 'wechat') {
                            // alert('wechat')
                            var res = response.res.data
                            // alert(JSON.stringify(res))
                            wx.ready(function () {
                                wx.chooseWXPay({
                                    appId: res.appId,
                                    timestamp: res.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                                    nonceStr: res.nonceStr, // 支付签名随机串，不长于 32 位
                                    package: res.packageValue, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
                                    signType: res.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                                    paySign: res.paySign, // 支付签名
                                    success: function (res) {
                                        // 支付成功后的回调函数
                                        console.log(res)
                                        self.$toast.success('支付成功');
                                        this.$router.push({ name: 'paysuccess' })
                                    },
                                    cancel: function (res) {
                                        console.log(res)
                                        // 支付失败后的回调函数
                                        // self.$toast.fail('支付失败');
                                        // location.href = window.location.origin + '/#/order/0';
                                    }
                                });
                            });
                        } else {
                          self.$toast.success('报读成功');
                          this.$router.push({ name: 'baodusuccess' })
                        }
                    }
                }).catch(() => {
                    this.submitting = false
                })

            },
            calcCountDown() {
                this.countDown--
                // console.log(this.countDown)
                if (this.countDown <= 0) {
                    this.countDown = 0
                    clearInterval(this.timer)
                    this.countDownTxt = ''
                    // this.countDown = 60 * 15
                } else {
                    this.setCountDown()
                }
            },
            setCountDown() {
                // 设置文字
                var minutes = parseInt(this.countDown / 60)
                var remainingSeconds = parseInt(this.countDown % 60);
                this.countDownTxt = minutes + '分钟' + (remainingSeconds < 10 ? '0'+remainingSeconds : + remainingSeconds) + '秒'
            },
            getConfirmCourses() {

                BaomingApi.getConfirmCourses(this.cust).then(response=>{
                  this.submitting = false
                    this.countDown = response.res.holdTime // 倒计时
                    this.notPaysExistBillNos = response.res.notPaysExistBillNos // 已经提交但没支付（有单号）（或者没有收到成功回调）
                    this.notPaysNoBillNos = response.res.notPaysNoBillNos // 没提交的数据（无单号）
                    this.notPayNoBillNoTotal = response.res.notPayNoBillNoTotal // 未支付并且没有支付单号的总额
                    this.notPayTotal = response.res.notPayTotal // 未支付总额
                    this.startCountDown()
                })
            },
            startCountDown() {
                this.setCountDown()
                this.timer = setInterval(()=>{
                    this.calcCountDown()
                }, 1000);
            },
            cancelCourse(regClassId) {
                // console.log(regClassId)
                if (this.submitting) {
                  return
                }
                this.submitting = true
                BaomingApi.cancelCourse({regClassId: regClassId}).then(response=>{
                    if (response.res == 1) {
                        this.$toast({message: '课程已取消。'})
                        this.getConfirmCourses()
                    }
                }).catch(() => { this.submitting = false })
            },
            cancelCourseForPay(regClassMasterId) {
                //  取消“已经发起过支付的单”
                if (this.submitting) {
                  return
                }
                this.submitting = true
                BaomingApi.cancelCourseForPay({regClassMasterId: regClassMasterId}).then(response=>{
                    if (response.res == 1) {
                        this.$toast({message: '支付单已取消。'})
                        this.getConfirmCourses()
                    }
                }).catch(() => { this.submitting = false })
            },
            createJsapiSignature() {
                BaomingApi.createJsapiSignature({url: window.location.href}).then(response => {
                    var res = response.res
                    wx.config({
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: res.appId, // 必填，公众号的唯一标识
                        timestamp: res.timestamp, // 必填，生成签名的时间戳
                        nonceStr: res.nonceStr, // 必填，生成签名的随机串
                        signature: res.signature, // 必填，签名，见附录1
                        jsApiList: ['checkJsApi', 'chooseWXPay']
                        // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                    });
                })
            }
        },
        mounted() {
            this.getConfirmCourses()
            this.createJsapiSignature()
        },
        destroyed() {
            clearInterval(this.timer)
        }
    }
</script>
<style scoped>
    .step-tips-detail {
        color: black;
        font-size: 14px;
        line-height: 25px;
    }

    .title-tips {
        margin-left: 2%;
        width: 160px;
        height: 35px;
        display: flex;
        font-size: 16px;
        font-weight: bold;
        justify-content: center;
        align-items: center;
        color: black;
    }

    .class-list {
        width: 95%;
        border-radius: 8px;
        border: 1px solid lightgrey;
        box-shadow: 1px 2px 2px #ddd;
        margin: 10px auto;
        padding: 8px 0px;
        background-color: #cde7f7;
    }

    .bottom-frame {
        display: flex;
        align-items: center;
        /* background-color: #cde7f7;*/
        background-color: white;
        color: black;
    }

    .bottom-frame-sum {
        flex: 4;
        text-align: center;
        font-weight: bold;
    }

    .bottom-frame-fun {
        flex: 3;
        display: flex;
        justify-content: flex-end;
    }


    .class-detail-more {
        display: flex;
    }
    .tipsTitle {
        border-radius: 3px; margin-left: 10px;background-color: darkorange;width: 75px; text-align: center;color: white;
    }
    .moneyTotal {
        margin-left: 15px;margin-right: 15px;display: flex;
    }
    .couponTipsBtn {
        position: fixed;
        right: 10px;
        top: 50%;
        background-color: #1989fa;
        color: white;
        font-size: 14px;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 2px solid white;
        box-shadow: 2px 3px 4px gray;
        z-index: 999;
    }
</style>
